import "../component/src/ui/modal/styles.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../component/src/ui/modal/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61VbYviSBD+7q8oFgYUpiVm1N3JcLDcwf2BWziOY1k66YrpnU536G6NzjL//egXNcboOLv3Sa2Xp56nqrr8/Iy7UtMaDXybrZ+3y3r7AD9GAKVWtf8CoBpacLvLIHkaAbyOAKzqu2bB9Tr6fA44vw4IYDWVplS6zsJXQS3+Mybp3QRMQQWOk+njcvJG8UswyR5kNrnCceExz6F/iuGQ1P+B47Lfx2GwWZLcnTfrUuEr9T7eVi+5tViH2Otouq9CfU6jDLdcyQxKvkXmorg0aOMEclo8r7RaS0YKJZTOYEP1mBBallwi6bhrxagIQb7WC+GS4baXEK0h2sdRyWsaGBwfwmyR1AZKpVuqmXk6oT11GUQqiV5AJ9/ZerG1yrkIgW9IEXSHmoT4A70OVn5Luxg3jaC7DEqBW69O8JUk3GJtMihQWtTO/H1tLC93pFDSorRd12196xJ7R0NibEkZ/uX2/otq+jnH4xGGUKxzXpAcXzjqcTKdp/eQ3EMyXXy6h/BqNhxb4tfNt4ZIWuMR59PJkEnJhXAqMBseb2RoBGf4u7I2voEBgktIpumDAaQG36bxeBONLOujKMHGBymTS1wWNzfrFyk8XqTwsdeO21teeEhC9r+TDJ5ODLO+IQ2GljNb7Rf1kO1lVshXle37QgtqLsmwPw1+uj34CyoKd782FRB4SJvtISIWPwS0QCBNYkCutsTwFy5XGeRKM9QkV/5Blkpa58LeA/P2nBqcHKLayGGe+LctXNie12y6dLaGMuaLuMqQzhtf43gkNApq+Qbfd0rVBrWgO9JQid2T6lVVlKm2l9uoxuVEZ4z1qjVlfG0ymKWBmlpbp2PgNLDefaO5UWJtPXWrmgzSCKFDB5zin7pX4UpdnUNFludjWA6OYb4IC6FXXJLcH4y92k7N0tdslWYk10ifM/AfxFn8JlfcIjENLTCDRiNpNW18vzaoS6FabzjN6sCvPPzZ8T+78s5BULIr/wxxo4hv+Tz2OMrzNrq2ytlWjk8YQpfJ1Hf8T6Us6r+5rf6ouGAaZZhuF3wgV+MGtYkDclwZ11jEVVYtif7L2oyl2p6AVh6sK2C/ir2Z7ffpMNokuTuZwO6o/fDsEpj3RPDhUfTVFEqsa+ky/2XU0rCnv30oqTD44evZnz534yG4QWnN8e0MpULvrPZzg4QO4e/vIxwHHzf8P4UDJ51GDAAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var closeButton = '_1ukx6mxd';
export var confirmModalContainer = '_1ukx6mxi';
export var confirmModalContent = '_1ukx6mxh';
export var heightVar = 'var(--_1ukx6mx1)';
export var minHeightVar = 'var(--_1ukx6mx2)';
export var modalContent = '_1ukx6mxc';
export var modalContentWrapper = '_1ukx6mxb';
export var modalDescription = '_1ukx6mxf';
export var modalFooter = '_1ukx6mxg';
export var modalHeader = '_1ukx6mxe';
export var modalOverlay = '_1ukx6mxa';
export var promptModalContent = '_1ukx6mxj';
export var widthVar = 'var(--_1ukx6mx0)';